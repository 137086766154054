import React, { useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import { AppContext } from "../util/AppContext";

const Sidebar = () => {
  const { logout,user } = useContext(AppContext)

  return (
    <>
      <div className="sidebar-overlay">
        <section className="sidebar">
          <Link to="/" className="logo">
            <img className="" src={ require("../assets/images/logo.png")} alt="" />
          </Link>

          <div className="under-tabs">
            <NavLink to="/home" className={({ isActive }) => 'sidebar_option' + (isActive ? ' active' : '' )}>
              <img src={ require("../assets/images/dashboard-1.svg").default }/>
                Dashboard
            </NavLink>

            <NavLink to="/users" className={({ isActive }) => 'sidebar_option' + (isActive ? ' active' : '' )}>
            <img src={ require("../assets/images/user.svg").default }/>
                User Management
            </NavLink>

            <NavLink to="/referrals" className={({ isActive }) => 'sidebar_option' + (isActive ? ' active' : '' )}>
            <img src={ require("../assets/images/users.svg").default }/>
                Referral Management
            </NavLink>
    
            {
              user?.role=='ADMIN' && 
              <NavLink to="/view-sub-admins" className={({ isActive }) => 'sidebar_option' + (isActive ? ' active' : '' )}>
                <img src={ require("../assets/images/sub-admin.svg").default }/>
                  Sub Admin Management
              </NavLink>
            }

            <NavLink to="/change-password" className={({ isActive }) => 'sidebar_option' + (isActive ? ' active' : '' )}>
            <img src={ require("../assets/images/lock.svg").default }/>
                Change Password
            </NavLink>

            <Link className={ "sidebar_option" } onClick={ logout }>
              <img src={ require("../assets/images/signout.svg").default }/>
                Sign out
            </Link>
          </div>

          <div className="sidebar-footer">
              <a target="_blank" href="https://houdiniplumbing.com.au/referral/">
                <img src={ require("../assets/images/sidebar-bottom.png")} />
              </a>
          </div>
        </section>
      </div>
    </>
  );
};
export default Sidebar;
